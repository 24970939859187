.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body{
}

.ContentPadding{
  padding: 0 50px;
}

.RowSpacing{
  margin-bottom: 25px;
}

.App-body {
  /*min-height: 100vh;*/
  color: white; 
}

.App-link {
  color: #61dafb;
}
.VideoSlider{
  height: 40px !important;
  margin: 1px 0px !important;
}

.VideoSliderColor {
  margin: 0px 0px !important;
}

.VideoSliderColor .ant-slider-rail {
  margin: 0px 0px !important;
  background-color: #282c34 !important;
}

.VideoSlider .ant-slider-rail {
  height: 40px !important;
  background-color: #282c34;
}

.VideoSlider .ant-slider-track {
  height: 40px !important;
}

.VideoSlider .ant-slider-handle
{
  height: 50px;
  width: 15px;
  opacity:0;
  border-radius: 0;
  background-color: #282c34;
  border-color: #282c34;

}
.VideoSlider .ant-slider-handle:hover {
    cursor:col-resize;
}

.CenterOverImageSpinner {
  overflow: hidden;
  position: relative !important;
}

.CenterOverImageSpinner img {
  position: relative;
}

.CenterOverImageSpinner div {
  transform: translate(-50%,-50%);
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
}

.ant-badge {
  display: inline !important;
}
